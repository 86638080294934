%grid-12 {
	position: relative;
	display: grid;
	display: -ms-grid;
	max-width: #{map_get($media-rules, large-desktop-width-max)};
	margin: 0 auto;
	padding: 0 16px;
	align-items: start;
	grid-column-gap: 16px;
	grid-template-columns: repeat(
		12,
		[col-start] minmax(0, #{map_get($media-rules, large-desktop-width-max)})
	);
	-ms-grid-columns: repeat(
		12,
		[col-start] minmax(0, #{map_get($media-rules, large-desktop-width-max)})
	);

  @include tablet {
    padding: 0 32px;
    grid-column-gap: 32px;
  }

	@include large-desktop {
    padding: 0 64px;
  }

	/* & > * {
		word-wrap: break-word;
	} */

	
}

%spacing-24 {
	p {
		margin: 24px 0;
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

%spacing-26 {
	p {
		margin: 26px 0;
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

%spacing-32 {
	p {
		margin: 32px 0;
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}