@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: "normal";
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

@mixin font-face($font-family, $file-path, $font-format, $font-weight, $font-style) {
	@font-face {
		font-family: $font-family;
		src: url($file-path) format($font-format);
		font-weight: $font-weight;
		font-style: $font-style;
		font-variant: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-display: swap;
	}
}

@include font-face("Gotham Rounded", "../../fonts/GothamRounded-Medium.otf", "opentype", 500, "normal");
@include font-face("Gotham Rounded", "../../fonts/GothamRounded-Bold.otf", "opentype", 700, "normal");