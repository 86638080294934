@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

// grid-12
.container {
  padding-top: 48px;
  padding-bottom: 48px;
  @include desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  > * {
    @include col-12;
  }

  h2 {
    text-align: center;
    margin-bottom: 24px;
    @include desktop {
      margin-bottom: 48px;
    }
  }

  .imgWrap {
    width: 100%;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 24px;
    @include tablet {
      @include col-6;
    }
    @include desktop {
      @include col-offset-1;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      pointer-events:none;
    }
  }

  .text {
    @include tablet {
      @include col-6;
      @include col-offset-6;
    }
    @include desktop {
      @include col-4;
      @include col-offset-7;
    }

    @extend %spacing-26;

    :global(.btn) {
      margin: 40px auto 0;
      @include desktop {
        margin: 32px 0 0;
      }
    }
  }


}