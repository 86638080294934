@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

// grid-12
.container {
  padding-top: 48px;
  padding-bottom: 48px;
  @include desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .content {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include tablet {
      @include col-10;
      @include col-offset-1;
    }
    @include desktop {
      gap: 48px;
      padding: 0 13px;
    }

    h2 {
      text-align: center;
    }

    h3 {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.005em;
      margin-top: 26px;
      @include tablet {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
      }
    }

    .imgWrap {
      width: 100%;
      height: auto;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        display: block;
        pointer-events:none;
      }
    }

    .text {
      @include tablet {
        columns: 2 auto;
        column-gap: 24px;
      }
      @include desktop {
        column-gap: 32px;
      }
    }

    .lower {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.18px;
      @include tablet {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
      }

      p:not(:global(.color--med-blue)) {
        @include tablet {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }

    @extend %spacing-26;
  }
}