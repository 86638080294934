@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

@keyframes line-anim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-blue-black;
  color: $color-white;
  padding: 32px 0 68px;
  @include desktop {
    padding: 56px 0 86px;
  }

  // grid-12
  .content {

    a {
      width: fit-content;
    }
    
    .logo {
      @include col-12;
      width: 173px;
      height: 65px;
      margin: 0 auto 40px;
      @include tablet {
        @include col-6;
        width: 100%;
        max-width: 390px;
        height: auto;
        aspect-ratio: 2.6/1;
        margin: 18px auto 0;
      }

      
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events:none;
      }
    }

    .info {
      @include col-12;
      padding: 0 16px;
      margin: 0 auto;
      display: flex;
      gap: 24px;
      @include tablet {
        @include col-6;
        @include col-offset-6;
        margin: 0;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .cat {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.01em;
          @include tablet {
            font-weight: 500;
          }

          .label {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $color-bright-blue;
          }

          address {
            max-width: 180px;
            @include tablet {
              max-width: unset;
            }
          }

          .socialWrap {
            display: flex;
            gap: 10px;
          }

          :global(.svg-icon) {
            width: 24px;
            height: 24px;
          }
        }
      }

      > div:first-child {
        width: 58.358%
      }

      > div:last-child {
        flex-grow: 1;
      }
    }

    .copywrite {
      @include col-12;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.04em;
      color: $color-bright-blue;
      padding: 24px 16px 0;
      margin: 0 auto;
      width: 361px;
      @include tablet {
        @include col-6;
        @include col-offset-6;
        font-weight: 600;
        width: unset;
        margin: 0;
      }
    }
  }

  .line {
    position: absolute;
    height: 16px;
    width: 1843.2px;
    left: 0;
    bottom: 24px;
    background-image: url(../../images/colored-lines.svg);
    background-size: 921.6px 16px;
    background-repeat: repeat-x;
    background-position: left bottom;
    animation: line-anim infinite 40s linear;
    @include desktop {
      width: 2880px;
      height: 25px;
      bottom: 16px;
      background-size: 1440px 25px;
    }
  }