@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: min(5%, 24px);
  @include large-desktop {
    row-gap: 32px;
    column-gap: min(5%, 32px);
  }

  > div {
    width: 168px;
    height: 152px;
    padding: 24px 16px;
    background-color: $color-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include desktop {
      width: 187px;
      /* row-gap: 32px;
      column-gap: min(5%, 32px); */
    }

    .iconWrap {
      width: 40px;
      height: 40px;
    }

    p {
      text-align: center;
      font-weight: 700;
      letter-spacing: 0.005em;
      color: $color-blue-black;
    }
  }
}