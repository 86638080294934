@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';


.container {
  width: 100%;
  position: relative;
  overflow: hidden;
  /* margin-top: -1px; */

  .wave {
    position: relative;
    margin-bottom: -2px;
    width: 100%;
    height: auto;
    aspect-ratio: 1/0.0687;
    z-index: 2;
    background-color: $color-faded-orange;
    @include tablet {
      aspect-ratio: 1/0.0492884415;
    }

    &[data-mask=true] {
      @include mask(url(../../images/wave-shape-2-m.svg) no-repeat bottom);
      @include mask-size(101%);
      @include tablet {
        @include mask-image(url(../../images/wave-shape-2-d.svg));
      }
    }
  }

  .radientLines {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 32.315%;
    height: auto;
    aspect-ratio: 1/1;
    z-index: 1;
    transform: rotate(90deg) translateX(1px);
    background-image: url(../../images/orange-radient-lines.svg);
    background-size: contain;
    @include tablet {
      max-width: 348px;
      max-height: 348px;
      width: 24.1666%;
    }
  }

  .contentWrap {
    position: relative;
    width: 100%;
    background-color: $color-faded-orange;

    .radientLines {
      bottom: unset;
      top: 0;
      transform: scale(-1,1) rotate(-90deg);
    }

    // grid-12
    .content {
      position: relative;
      padding-top: 24px;
      padding-bottom: 48px;
      @include desktop {
        padding-top: 48px;
        padding-bottom: 100px;
      }

      .imgWrap {
        @include col-12;
        margin-bottom: 32px;
        @include tablet {
          @include col-6;
          margin-bottom: 0;
          max-width: 542px;
          max-height: 630px;
        }
        @include large-desktop {
          @include col-5;
          @include col-offset-1;
        }
        
        img {
          width: 100%;
          height: auto;
          max-height: 630px;
          object-fit: contain;
          @include tablet {
            padding-top: 8px;
          }
          pointer-events:none;
        }
      }

      .textContent {
        @include col-12;
        @include tablet {
          @include col-6;
          @include col-offset-6;
          max-width: 504px;
        }
        @include desktop {
          @include col-5;
          @include col-offset-7;
        }

        h2 {
          text-align: center;
          @include tablet {
            text-align: left;
          }
        }

        p {
          margin: 24px 8px;
          @include desktop {
            margin: 32px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.01em;
          }
        }
        
      }

    }
  }
}