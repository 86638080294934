@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

.container {
  width: 100%;
  position: relative;

  .btn {
    grid-area: button;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.18px;
    padding: 20px 24px;
    position: relative;

    @include desktop {
      font-size: 20px;
      letter-spacing: -0.2px;
      padding: 32px;
    }
  }

  .content {
    grid-area: content;
    height: 100%;
    padding: 0 24px;
    overflow: hidden;
    display: grid;
    grid-template-rows: minmax(0px, 0%);
    transition: all 0.25s ease-out;
    @include desktop {
      padding: 0 32px;
    }

    .inner {
      grid-area: 1/1;
      max-height: 0vh;
      padding-bottom: 0px;
      line-height: 28px;
      transition: all 0.25s ease-out;

      > *:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }

  &[data-show="true"] {
    .content { 
      grid-template-rows: minmax(20px, 100%);
      .inner {
        padding-bottom: 16px;
        max-height: 200vh;
        @include desktop {
          padding-bottom: 32px;
        }
      }
    }
  }
}