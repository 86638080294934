@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.surveySection {
  display: grid;
  position: relative;
  z-index: 4;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */

  > * {
    grid-area: 1/1;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px;
    @include tablet {
      padding: 48px;
    }
    @include desktop {
      padding: 52px;
    }
    @include medium-desktop {
      padding: 64px;
    }

    h2, h3 {
      text-align: center;
      max-width: 1062px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    pointer-events:none;
  }
}