@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.container {
  padding: 48px 0;
  background-color: $color-faded-orange;
  @include desktop {
    padding: 80px 0;
  }

  :global(.grid-12) {
    @include tablet {
      padding: 0 64px;
    }
    @include desktop {
      padding: 0 80px;
    }
  }

  // parent grid-12
  .content {
    @include col-12;

    h2 {
      text-align: center;
      margin-bottom: 32px;
      @include desktop {
        margin-bottom: 48px;
      }
    }

    .images {
      display: flex;
      flex-direction: column;
      gap: 32px;
      @include tablet {
        flex-direction: row;
      }

      .imgWrap {
        width: 100%;
        height: 527px;
        height: auto;
        border-radius: 16px;
        overflow: hidden;
        display: grid;
        @include tablet {
          height: auto;
          max-height: 597px;
          justify-content: center;
        }

        > * {
          grid-area: 1/1;
          position: relative;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          pointer-events:none;
        }

        .imgText {
          height: 100%;
          color: $color-white;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          letter-spacing: -0.005em;
          padding: 40px 24px;
          @include medium-desktop {
            font-size: 28px;
            line-height: 32px;
          }

          p {
            margin-top: 8px;
          }
        }

        :global(.btn) {
          align-self: flex-end;
          width: calc(100% - 48px);
          margin: 0 auto 40px;
          @include tablet {
            @include medium-btn;
            font-weight: 600;
          }
          @include medium-desktop {
            @include large-btn;
          }
        }
      } 
    }
  }
}