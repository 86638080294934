@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';


.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
  width: 100%;
  border-radius: 16px;
  @include subtle-lift;
  --bg-color: #{rgba($color-white, 1)};
  background-color: var(--bg-color);

  &[data-on-white="true"] {
    @include blue-lift;
  }


  @include tablet {
    flex-direction: row;
    padding: 32px;
    transition: background-color 0.35s ease-out;
    
    > div:first-child {
      width: 60%;
      p {
        max-height: 350px;
        overflow: hidden;
        transition: max-height 0.35s ease-out;
      }
      
    }
    > div:last-child {
      width: auto;
      ul {
        max-height: 350px;
        overflow: hidden;
        transition: max-height 0.35s ease-out;
      }
      h3 {
        text-align: right;
      }
    }
  }

  &[data-reveal=false] {
    @include tablet {
      border-radius: 0px;
      border-bottom: 1px solid;
      background-color: rgba($color-white, 0);
      box-shadow: none;
      > div:first-child {
        p {
          max-height: 0px;
          /* transition: none; */
        }
      }
      > div:last-child {
        gap: 8px;
        ul {
          max-height: 0px;
          /* transition: none; */
        }
      }

      *:not(:global(.btn)) {
        pointer-events: none;
      }

      .price span,
      .subhead {
        color: $color-white;
        transition: color 0.35s ease-out;
        &[data-on-white=true] {
          color: $color-warm-black;
        }
      }

      :global(.btn) {
        color: $color-bright-blue;
        background-color: transparent !important;
        border: 2px solid $color-bright-blue;
        transition: all 0.35s ease-out;
      }
    }
    
  }

  &:has(+ [data-reveal=true]) {
    @include tablet {
      border: none;
    }
  }

  &:nth-child(even) {
    h3 {
      color: $color-yellow;
    }
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }

  > div:first-child {
    gap: 8px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  > div:last-child {
    gap: 16px;
    flex-basis: 55%;

    ul {
      margin: 0;
      padding-left: 24px;
    }
  }

  .price {
    span {
      font-family: $font-montserrat;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: $color-warm-black;
      margin-left: 4px;
    }
  }

  .subhead {
    color: $color-med-blue;
    font-weight: 600;
  }

  :global(.btn) {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03em;
    color: $color-dark-blue;
    background-color: $color-bright-blue;
    align-self: flex-end;
  }
}