/* SETTINGS */
@import './settings/normalize';
@import './settings/fonts';
@import './settings/vars';
@import './settings/mixins';
@import './settings/extensions';

/* GLOBAL */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

html {
	scroll-padding-top: $header-height-mobile;
  @include desktop {
    scroll-padding-top: $header-height-tablet;
    transition: scroll-padding-top 0.25s;
    &:has(.header[data-scroll="true"]) {
      scroll-padding-top: $header-height-mobile;
    }
  }
}

body {
  color: $color-warm-black;
	font-family: $font-montserrat;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: normal;
	font-display: swap;
}

.grid-12 {
  @extend %grid-12;

  &--full-width-mobile {
    @include mobile-only {
      padding: 0;
    }
	}
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic !important;
}

.margin-auto {
 margin: 0 auto !important;
}

.main-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  /* letter-spacing: -0.005em */;
  letter-spacing: 0.08px;
}

@each $color, $i in $colors {
  .color--#{$color} {
    color: $i !important;
  }
  .bg-color--#{$color} {
    background-color: $i !important;
  }
  .fill--#{$color} {
    fill: $i !important;
  }
  .border-color--#{$color} {
    border-color: $i !important;
  }
}

.color--orange-gradient {
  @include orange-gadient-text;
}

*:focus-visible,
a:focus-visible,
button:focus-visible {
  outline: 1.5px dashed $color-black;
  outline-offset: 3px;
  &.focus-contrast {
    outline-color: $color-white;
  }
}

/* LIBS */
@import './lib/headlines';
@import './lib/forms';