@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

.container {
  width: 100%;
  padding: 60px 0;

  // parent is .grid-12
  .header,
  .content {
    @include col-12;
    @include tablet {
      padding: 0 32px;
    }
    @include desktop {
      padding: 0 16px;
    }
  }

  .header {
    h2, h3 {
      text-align: center;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
      margin-top: 16px;
      @include desktop {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.14px;
      }
    }
  }

  .content {
    margin-top: 24px;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "faqs video";
      gap: 32px;
      margin-top: 48px;
    }

    > div:first-child {
      width: 100%;
      margin-bottom: 16px;
      @include tablet {
        grid-area: faqs;
        margin-bottom: 0;
      }

      .faq {
        border-bottom: 1px solid $color-ui-grey-4;
      }
    }

    > div:last-child {
      width: 100%;
      @include tablet {
        grid-area: video;
      }

      .inquiries {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin-top: 24px;
        padding: 8px 16px;
        width: 100%;
        border-radius: 8px;
        background-color: $color-faded-orange;
        @include tablet {
          padding: 16px;
        }
        @include desktop {
          flex-direction: row;
          justify-content: center;
        }

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.08px;
          color: $color-dark-blue;
        }
      }

    }


  }
}