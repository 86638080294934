@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

.header {

  a:has(.header__nav-link) {
    @include desktop {
      &:hover,
      &:focus {
        .header__nav-link {
          color: $color-dark-blue;
          text-decoration: underline;
          text-underline-offset: 6px;
          text-decoration-thickness: 2px;
        }
      }
    }
  }

  &__nav-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $color-ui-black;
    @include desktop {
      font-weight: 500;
    }

    &--sign-in {
      @include desktop {
        font-size: 14px;
        font-weight: 500;
        color: $color-dark-blue;
        &:hover,
        &:focus {
          text-decoration: underline;
          text-underline-offset: 6px;
          text-decoration-thickness: 2px;
        }
      }
      @include medium-desktop {
        font-size: 16px;
      }
    }
  }

  &__nav-link-header {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $color-dark-blue;
    width: fit-content;
    margin-bottom: 26px;

    > * {
      pointer-events: none;
    }

    @include desktop {
      font-weight: 500;
      /* font-size: 16px;
      line-height: 20px; */
      letter-spacing: unset;
      color: $color-ui-black;
      text-transform: unset;
      height: 34px;
      margin: 0;

      &[data-active=true] {
        color: $color-med-blue;
        text-decoration: underline;
        text-underline-offset: 6px;
        text-decoration-thickness: 2px;
      }

      @include medium-desktop {
        font-size: 16px;
      }
    }
  }

  .nav-wrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 52px;
    left: 0;
    width: 100%;
    height: calc(100% - 52px);
    background-color: rgba($color-ui-black, 0);
    z-index: 100;
    visibility: hidden;
    transition: background-color 0.2s ease-out, visibility 0.3s ease-out;
    overflow-y: scroll;
    &[data-show=true] {
      visibility: visible;
      background-color: rgba($color-ui-black, 0.1);
      nav {
        transform: translate3d(0%, 0, 0);
      }
    }

    @include tablet {
      top: 84px;
      height: calc(100% - 84px);
    }
    @include desktop {
      grid-area: nav;
      position: unset;
      width: min(48vw, 600px);
      margin: 0;
      top: unset;
      left: unset;
      height: auto;
      transform: unset;
      overflow-y: unset;
      visibility: visible;
      background-color: unset;
    }
    @include medium-desktop {
      margin: 0 auto;
    }
  }

  .sign-in-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0 24px;
    border-top: 1px solid $color-ui-grey-5;
    @include desktop {
      display: none;
    }

    .svg-icon {
      width: 24px;
      height: 24px;
    }
  }

  nav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 32px 24px 52px;
    width: 100%;
    height: fit-content;
    background-color: $color-white;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s ease-out;
    border-top: 1px solid $color-ui-grey-5;
    @include tablet {
      width: 50%;
    }
    @include desktop {
      flex-direction: row;
      justify-content: space-between;
      transform: unset;
      border: none;
      width: 100%;
      padding: 0;
      background-color: unset;
    }
  }

  .sub-nav {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin: 0 0 46px;
    padding: 0;
    @include desktop {
      display: none;
      flex-direction: row;
      justify-content: center;
      gap: 32px;
      width: 100%;
      margin: 0;
      padding: 24px 24px 20px;
      background-color: $color-white;
      position: absolute;
      left: 0;
      top: $header-height-tablet;
      transition: top 0.25s;
      &[data-show=true] {
        display: flex;
      }
    }

    li {
      @include desktop {
        width: 100%;
        max-width: 282px;
      }

      a {
        @include desktop {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
        }
      }

      img {
        display: none;
        pointer-events:none;
        @include desktop {
          display: block;
          aspect-ratio: 1.7625/1;
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 8px;
          overflow: hidden;
          background-color: $color-ui-grey-4;
        }
      }
      
    }
  }

  &[data-scroll="true"] {
    .sub-nav {
      @include desktop {
        top: $header-height-mobile;
      }
    }
  }
}