@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

// grid-12
.container {
  padding-top: 48px;
  padding-bottom: 94px;

  > div:first-child {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 505px;
    margin: 0 auto;
    @include tablet {
      @include col-6;
      margin: unset;
    }
    @include desktop {
      @include col-5;
      @include col-offset-1;
      gap: 32px;
      padding-right: 16px;
    }
    @include large-desktop {
      padding-right: 0;
    }

    h2 {
      text-align: center;
    }

    h3 {
      text-align: center;
    }

    .lists {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 8px;
      padding: 16px 8px;

      > div:first-child {
        grid-area: 1/1;
      }

      > div:last-child {
        grid-area: 1/2;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
          color: $color-white;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.01em;
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          @include desktop {
            font-size: 16px;
            line-height: 20px;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    :global(.btn) {
      width: 303px;
      margin: 0 auto;
      @include tablet {
        width: 100%;
      }
    }
  }

  > div:last-child {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px;
    @include tablet {
      @include col-6;
      @include col-offset-6;
      margin-top: 0px;
      gap: 0px;
    }

    .btmContent {
      text-align: center;
      color: $color-white;
      width: 316px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include tablet {
        text-align: left;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        width: 100%;
        padding: 24px 32px;
      }

      :global(.btn) {
        margin: 0 auto;
        @include tablet {
          margin: 0;
        }
      }
    }
  }
}