// ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
// ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
// ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
// ▒ Reset --- HTML5 display definitions
// ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒

/**
 * Reset most elements
 */
 @charset "UTF-8";
 ::after, ::before, a, abbr, acronym, address, applet, area, article, aside, audio, b, base, basefont, bdo, big, blockquote, body, br, button, canvas, caption, center, cite, code, col, colgroup, datalist, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, frame, frameset, h1, h2, h3, h4, h5, h6, head, header, hgroup, hr, html, i, iframe, img, input, ins, kbd, keygen, label, legend, li, link, map, mark, math, menu, meta, meter, nav, nobr, noembed, noframes, noscript, object, ol, optgroup, option, output, p, param, picture, pre, progress, q, rp, rt, ruby, s, samp, script, section, select, small, source, span, strike, strong, style, sub, summary, sup, svg, table, tbody, td, textarea, tfoot, th, thead, time, title, tr, tt, u, ul, var, video, wbr {
   margin: 0;
   padding: 0;
   border: 0;
   -moz-border-radius: 0;
   -ms-border-radius: 0;
   -o-border-radius: 0;
   -webkit-border-radius: 0;
   border-radius: 0;
   outline: 0;
   background: transparent;
   font-family: inherit;
   font-size: 100%;
   font-weight: inherit;
   font-style: inherit;
   color: inherit;
   -moz-opacity: inherit;
   -ms-opacity: inherit;
   -o-opacity: inherit;
   -webkit-opacity: inherit;
   opacity: inherit;
   vertical-align: baseline;
   pointer-events: inherit;
 }
 
 /**
  * Ensures block is used on these elements and corrects `block` display not defined in IE 6/7/8/9 and Firefox 3.
  * Additionally, converting button to block over inline-block due to the nature of how we typically use it (CTA)
  */
 article, aside, button, details, figcaption, figure, footer, header, menu, nav, section, summary {
   display: block;
 }
 
 /**
  * Set headings to be displayed as block to be more inline how they are typically displayed
  */
 h1, h2, h3, h4, h5, h6 {
   display: block;
 }
 
 /**
  * Corrects `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
  */
 audio,
 canvas,
 video {
   display: inline-block;
   *display: inline;
   *zoom: 1;
 }
 
 /**
  * Prevents modern browsers from displaying `audio` without controls.
  * Remove excess height in iOS 5 devices.
  */
 audio:not([controls]) {
   display: none;
   height: 0;
 }
 
 /**
  * Addresses styling for `hidden` attribute not present in IE 7/8/9, Firefox 3,
  * and Safari 4.
  * Known issue: no IE 6 support.
  */
 [hidden] {
   display: none;
 }
 
 /**
  * Expected box layout model to all elements
  */
 *, *:before, *:after {
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
 }
 
 /* ==========================================================================
 Base
 ========================================================================== */
 
 /**
  * 1. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using `em` units.
  * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom.
  */
 html {
   font-size: 100%; /* 1 */
   -webkit-text-size-adjust: 100%; /* 2 */
   -ms-text-size-adjust: 100%; /* 2 */
 }
 
 /**
  * Addresses `font-family` inconsistency between `textarea` and other form
  * elements.
  */
 html,
 button,
 input,
 select,
 textarea {
   font-family: sans-serif;
 }
 
 /**
  * Adds smooth scrolling support for anchor ('a') elements hashed links,
  * as this enables them to smoothly travel up or down the page resting on their intended target.
  * Prevent content from getting wider than browser (Google Webmaster) by hiding with `overflow-x'.
  */
 html, body {
   overflow-x: hidden;
   scroll-behavior: smooth;
 }
 
 /* ==========================================================================
 Links
 ========================================================================== */
 
 /**
  * Reset all focused elements have
  */
 :focus {
   margin: 0;
   padding: 0;
   border: 0;
   outline: 0;
 }
 
 /**
  * Set preferred cursor
  * Remove default tap color on iOS
  */
 p, span, div {
   cursor: default;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 /**
  * Reset links with imgs and textareas
  */
 a img, textarea {
   border: 0 none;
   outline: none;
   background: transparent;
 }
 
 /**
  * Set preferred cursor
  * Remove default tap color on iOS
  */
 a {
   cursor: pointer;
   text-decoration: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 /**
  * Remove default tap color on iOS
  */
 a:link {
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 /**
  * Set preferred cursor
  * Remove default tap color on iOS
  */
 a:hover {
   cursor: pointer;
   text-decoration: none;
 }
 
 /**
  * Addresses `outline` inconsistency between Chrome and other browsers.
  */
 a:focus {
   outline: thin dotted;
 }
 
 /**
  * Improves readability when focused and also mouse hovered in all browsers.
  */
 a:active,
 a:hover {
   outline: 0;
 }
 
 /* ==========================================================================
 Typography
 ========================================================================== */
 
 /**
  * Addresses font sizes and margins set differently in IE 6/7.
  * Addresses font sizes within `section` and `article` in Firefox 4+, Safari 5,
  * and Chrome.
  */
 /* h1 {
   margin: 0.67em 0;
   font-size: 2em;
 }
 
 h2 {
   margin: 0.83em 0;
   font-size: 1.5em;
 }
 
 h3 {
   margin: 1em 0;
   font-size: 1.17em;
 }
 
 h4 {
   margin: 1.33em 0;
   font-size: 1em;
 }
 
 h5 {
   margin: 1.67em 0;
   font-size: 0.83em;
 }
 
 h6 {
   margin: 2.33em 0;
   font-size: 0.75em;
 } */
 
 /**
  * Addresses styling not present in IE 7/8/9, Safari 5, and Chrome.
  */
 abbr[title] {
   border-bottom: 1px dotted;
 }
 
 /**
  * Addresses style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
  */
 b,
 strong {
   font-weight: bold;
 }
 
 //blockquote {
 //	margin: 1em 40px;
 //}
 
 
 /**
  * Give definition element italic presentation
  */
 dfn {
   font-style: italic;
 }
 
 /**
  * Addresses styling not present in IE 6/7/8/9.
  */
 mark {
   background: #ff0;
   color: #000;
 }
 
 /**
  * Addresses margins set differently in IE 6/7.
  */
 //p,
 pre {
   margin: 1em 0;
 }
 
 /**
  * Corrects font family set oddly in IE 6, Safari 4/5, and Chrome.
  */
 code,
 kbd,
 pre,
 samp {
   font-family: monospace, serif;
   font-size: 1em;
   _font-family: 'courier new', monospace;
 }
 
 /**
  * Improves readability of pre-formatted text in all browsers.
  */
 pre {
   white-space: pre;
   white-space: pre-wrap;
   word-wrap: break-word;
 }
 
 /**
  * Addresses CSS quotes and blockquotes not supported in IE 6/7.
  */
 q, blockquote {
   quotes: none;
 }
 
 /**
  * Addresses `quotes` and `blockquotes` property not supported in Safari 4 and above.
  */
 q:before, q:after, blockquote:before, blockquote:after {
   content: "";
 }
 
 
 /**
  * Addresses inconsistent and variable font size in all browsers.
  */
 small {
   font-size: 80%;
 }
 
 //  Add in default styles for subscripted copy
 // ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
 /**
  * Prevents `sub` and `sup` affecting `line-height` in all browsers.
  */
 sub, sup {
   position: relative; /* Makes all browsers position the sup/sup properly, relative to the surrounding copy */
   font-size: 75%; /* Specified in % so that the sup/sup is the correct size relative to the surrounding copy */
   line-height: 0; /* Zero out the line-height so that it doesn't interfere with the positioning that follows */
   vertical-align: baseline;
 }
 
 /**
  * Move the superscripted copy up
  */
 sup {
   top: -0.5em;
 }
 
 /**
  * Move the subscripted copy down, but only half as far down as the superscript moved up
  */
 sub {
   bottom: -0.25em;
 }
 
 
 /* ==========================================================================
 Lists
 ========================================================================== */
 
 /**
  * Addresses margins set differently in IE 6/7.
  */
 dl,
 menu,
 ol,
 ul {
   margin: 1em 0;
 }
 
 dd {
   margin: 0 0 0 40px;
 }
 
 /**
  * Addresses paddings set differently in IE 6/7.
  */
 menu,
 ol,
 ul {
   padding: 0 0 0 40px;
 }
 
 /**
  * Remove decoration from lists when they appear as part of a navigation.
  * Corrects list images handled incorrectly in IE 7.
  */
 nav ul,
 nav ol {
   list-style: none;
   list-style-image: none;
 }
 
 /* ==========================================================================
 Embedded content
 ========================================================================== */
 
 /**
  * Improves image quality when scaled in IE 7.
  */
 img {
   -ms-interpolation-mode: bicubic;
 }
 
 /**
  * Corrects overflow displayed oddly in IE 9.
  */
 svg:not(:root) {
   overflow: hidden;
 }
 
 /* ==========================================================================
 Figures
 ========================================================================== */
 
 /**
  * Addresses margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
  */
 figure {
   margin: 0;
 }
 
 /* ==========================================================================
 Forms
 ========================================================================== */
 
 /**
  * Corrects margin displayed oddly in IE 6/7.
  */
 form {
   margin: 0;
 }
 
 /**
  * Define consistent border, margin, and padding.
  */
 fieldset {
   margin: 0 2px;
   padding: 0.35em 0.625em 0.75em;
   border: 1px solid #c0c0c0;
 }
 
 /**
  * 1. Corrects color not being inherited in IE 6/7/8/9.
  * 2. Corrects text not wrapping in Firefox 3.
  * 3. Corrects alignment displayed oddly in IE 6/7.
  */
 legend {
   *margin-left: -7px; /* 3 */
   padding: 0;
   border: 0; /* 1 */
   white-space: normal; /* 2 */
 }
 
 /**
  * 1. Corrects font size not being inherited in all browsers.
  * 2. Addresses margins set differently in IE 6/7, Firefox 3+, Safari 5, and Chrome.
  * 3. Improves appearance and consistency in all browsers.
  */
 button,
 input,
 select,
 textarea {
   margin: 0; /* 2 */
   font-size: 100%; /* 1 */
   vertical-align: baseline; /* 3 */
   *vertical-align: middle; /* 3 */
 }
 
 /**
  * Addresses Firefox 3+ setting `line-height` on `input` using `!important` in
  * the UA stylesheet.
  */
 button,
 input {
   line-height: normal;
 }
 
 /**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls.
  * 2. Corrects inability to style clickable `input` types in iOS.
  * 3. Improves usability and consistency of cursor style between image-type `input` and others.
  * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
  * 5. Adding `user-select: none` to all buttons and button-like elements.
  */
 input[type="submit"],
 input[type="reset"],
 input[type="button"],
 button {
   padding: 0;
   border: 0;
   background: none;
   font: inherit;
   line-height: normal;
   color: inherit;
   *overflow: visible; /* 4 */
   cursor: pointer; /* 3 */
   -moz-user-select: none;
   -ms-user-select: none;
   -webkit-user-select: none; /* for button */
   user-select: none;
   -webkit-appearance: button; /* 2 - for input */
 }
 
 //  Inputs and buttons
 // ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
 
 button::-moz-focus-inner,
 input::-moz-focus-inner {
   padding: 0;
   border: 0;
 }

input::-webkit-calendar-picker-indicator {
  display: none !important;
}
 
 /**
  * Re-set default cursor for disabled elements.
  */
 button[disabled],
 input[disabled] {
   cursor: default;
 }
 
 /**
  * 1. Addresses box sizing set to content-box in IE 8/9.
  * 2. Removes excess padding in IE 8/9.
  * 3. Removes excess padding in IE 7.
  * Known issue: excess padding remains in IE 6.
  */
 input[type="checkbox"],
 input[type="radio"] {
   *width: 13px; /* 3 */
   *height: 13px; /* 3 */
   padding: 0; /* 2 */
   box-sizing: border-box; /* 1 */
 }
 
 /**
  * 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
  * 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome (include `-moz` to future-proof).
  */
 input[type="search"] {
   -moz-box-sizing: content-box;
   -webkit-box-sizing: content-box; /* 2 */
   box-sizing: content-box;
   -webkit-appearance: textfield; /* 1 */
 }
 
 
 /**
  * Removes inner padding and search cancel button in Safari 5 and Chrome on OS X.
  */
 input[type="search"]::-webkit-search-cancel-button,
 input[type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 
 /**
  * Removes inner padding and border in Firefox 3+.
  */
 button::-moz-focus-inner,
 input::-moz-focus-inner {
   padding: 0;
   border: 0;
 }
 
 /**
  * 1. Removes default vertical scrollbar in IE 6/7/8/9.
  * 2. Improves readability and alignment in all browsers.
  */
 textarea {
   overflow: auto; /* 1 */
   vertical-align: top; /* 2 */
 }
 
 /**
  * Set default width of input type button
  */
 input[type="button"] {
   width: 100px;
 }
 
 /* ==========================================================================
 Tables
 ========================================================================== */
 
 /**
  * Remove most spacing between table cells.
  */
 table {
   border-spacing: 0;
   border-collapse: collapse;
 }
 
 /**
  * Set base styles for table headers, columns (data fields) and captions
  */
 caption, th, td {
   font-weight: normal;
   text-align: left;
   vertical-align: middle;
 }
 
 /**
  * Remove clear and reveal password icons from >= IE10
  */
 input::-ms-clear, input::-ms-reveal {
   display: none;
 }
 