@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

@mixin large-btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  padding: 12px 32px;
  border-radius: 36px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-align: center;
  font-family: $font-montserrat;
  @include large-btn;
  margin: 0;
  width: fit-content;
  height: fit-content;
  color: $color-white;
  background-color: $color-dark-blue;
  flex-shrink: 0;

  svg {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
    pointer-events: none;
    fill: $color-white;
  }

  &--lg-extra-padding {
    padding: 20px 32px
  }

  &[disabled],
  &[data-disabled=true] {
    color: $color-ui-grey-5;
    background-color: $color-ui-grey-3;
    pointer-events: none;
    svg {
      fill: $color-ui-grey-5;
    }
  }

  &[data-border=true] {
    color: $color-dark-blue;
    background-color: transparent !important;
    border: 2px solid $color-dark-blue;
    svg {
      fill: $color-dark-blue;
    }
    &[disabled],
    &[data-disabled=true] {
      color: $color-ui-grey-3;
      border-color: $color-ui-grey-3;
      svg {
        fill: $color-ui-grey-3;
      }      
    }
  }

  &[data-full-width=true] {
    width: 100%;
  }

  &[data-gradient=true] {
    @include gradient-blue;
  }


  &[data-secondary=true] {
    color: $color-white;
    background-color: $color-orange;
    svg {
      fill: $color-orange;
    } 

    &[disabled],
    &[data-disabled=true] {
      color: $color-ui-grey-3;
      background-color: $color-ui-grey-blue-4;
      svg {
        fill: $color-ui-grey-3;
      } 
    }

    &[data-border=true] {
      color: $color-dark-blue;
      border-color: $color-dark-blue;
      &[disabled],
      &[data-disabled=true] {
        color: $color-ui-grey-blue-4;
        border-color: $color-ui-grey-blue-4;
        svg {
          fill: $color-ui-grey-blue-4;
        }
      }
    }
  }

  @mixin medium-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding: 8px 20px;
    border-radius: 28px;
  }

  &--md{
    @include medium-btn;
    &.btn--md-extra-padding {
      padding: 15px 20px;
    }
    &-to-lg {
      @include desktop {
        @include large-btn;
        &.btn--lg-extra-padding {
          padding: 20px 32px
        }
      }
    }
  }

  &--sm {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    padding: 8px;
    border-radius: 20px;

    &-to-md {
      @include tablet {
        @include medium-btn;
      }
    }
  }
  
}