
@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.video-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1280/720;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  @include tablet {
    border-radius: 8px;
  }

  > * {
    position: absolute;
  }

  iframe,
  video {
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-poster {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-play-btn {
    z-index: 2;
    width: auto;
    height: 38.86%;
    aspect-ratio: 1/1;
    transform: scale(1);
    opacity: 1;
    transition: all 0.25s ease-in-out;
    pointer-events: none;

    &[data-show=false] {
      transform: scale(0.8);
      opacity: 0;
    }
  }

  &:hover {
    .video-play-btn {
      transform: scale(1.15);
    }
  }
}