@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

@keyframes radial-anim {
  0% {
    transform: scale(1) translate3d(0,0,0);
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(58) translate3d(0,0,0);
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  width: 100%;
  padding: 48px 0 0;
  background-color: $color-dark-blue;

  :global(.grid-12) {
    transition: all 0.5s ease-out;
    &[data-wide="true"] {
      max-width: 100%;
      padding: 0px;

      .content {
        padding: 0 16px;
        @include tablet {
          padding: 0 32px;
        }
        @include large-desktop {
          padding: 0 64px;
        }
      }
    }
  }

  // parent is .grid-12
  .content {
    @include col-12;
    padding: 0px;
    transition: padding 0.5s ease-out;
    @include tablet {
      @include col-10;
      @include col-offset-1;
    }

    h2, p {
      text-align: center;
    }

    p {
      margin: 24px auto;
      max-width: 1062px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.01em;
    }

    .btnWrap {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 48px;

      label {
        color: $color-white;
        border: 2px solid $color-white;
        border-radius: 36px;
        padding: 12px 32px;
        position: relative;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        cursor: pointer;

        &[data-checked=true] {
          color: $color-dark-blue;
          background-color: $color-white;
        }

        input {
          visibility: hidden;
        }
      }
    }
  }

  .imgWrap {
    @include col-12;
    width: 100%;
    height: auto;
    aspect-ratio: 724/407;
    border-radius: 4px;
    margin-bottom: 48px;
    overflow: hidden;
    position: relative;
    background-color: #d9e2e5;
    transform: translateX(0%);
    transition: all 0.5s ease-out;
    @include tablet {
      @include col-10;
      @include col-offset-1;
      aspect-ratio: 2124/1193;
    }

    &[data-wide="true"] {
      margin-bottom: 0px;
      border-radius: 0px;
      @include tablet {
        width: 100vw;
        transform: translateX(-8.5%);
      }

      .radial {
        &[data-first] {
          animation: radial-anim 6s ease-in 0s infinite;
        }
        &[data-second] {
          animation: radial-anim 6s ease-in 3s infinite;
        }
      }

      picture:nth-of-type(2) img {
        animation: fade-in 0.8s linear 2s forwards;
      }
    }

    picture {
      position: relative;
      z-index: 1;
      &:nth-of-type(2) {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        
        img {
          opacity: 0;
          will-change: opacity;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      pointer-events:none;
    }

    .radial {
      opacity: 0;
      background-color: rgba($color-bright-blue, 0.5);
      border-radius: 50%;
      height: 2.2%;
      width: 1.2%;
      transform-origin: center;
      position: absolute;
      z-index: 2;

      &[data-index="1"] {
        &[data-type="single"] {
          top: 31.5%;
          left: 44.1%;
        }
        &[data-type="apt"] {
          top: 56.9%;
          left: 35.1%;
        }
        &[data-type="condo"] {
          top: 37.2%;
          left: 23.95%;
        }
      }

      &[data-index="2"] {
        &[data-type="single"] {
          top: 64.2%;
          left: 58.55%;
        }
        &[data-type="apt"] {
          top: 54.5%;
          left: 69.6%;
        }
        &[data-type="condo"] {
          top: 52.2%;
          left: 59.1%;
        }
      }
    }
  }
}