@use 'sass:math';

@function em($target, $context: 16) {
	@return math.div($target, $context)*1em;
}

@function rem($target, $context: 16) {
	@return math.div($target, $context)*1rem;
}


@mixin mobile-only {
	@media (max-width: #{ map_get($media-rules, tablet-width-min) - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{ map_get($media-rules, tablet-width-min)}) {
		@content;
	}
}

@mixin tablet-only {
	@media (min-width: #{ map_get($media-rules, tablet-width-min)}) and (max-width: #{ map_get($media-rules, desktop-width-min) - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{ map_get($media-rules, desktop-width-min)}) {
		@content;
	}
}

@mixin medium-desktop {
	@media (min-width: #{ map_get($media-rules, medium-desktop-width-min)}) {
		@content;
	}
}

@mixin large-desktop {
	@media (min-width: #{ map_get($media-rules, large-desktop-width-min)}) {
		@content;
	}
}

@mixin x-large-desktop {
	@media (min-width: #{ map_get($media-rules, large-desktop-width-max)}) {
		@content;
	}
}

// Column span end
@mixin col-12 {
	grid-column-end: span 12;
}

@mixin col-11 {
	grid-column-end: span 11;
}

@mixin col-10 {
	grid-column-end: span 10;
}

@mixin col-9 {
	grid-column-end: span 9;
}

@mixin col-8 {
	grid-column-end: span 8;
}

@mixin col-7 {
	grid-column-end: span 7;
}

@mixin col-6 {
	grid-column-end: span 6;
}

@mixin col-5 {
	grid-column-end: span 5;
}

@mixin col-4 {
	grid-column-end: span 4;
}

@mixin col-3 {
	grid-column-end: span 3;
}

@mixin col-2 {
	grid-column-end: span 2;
}

@mixin col-1 {
	grid-column-end: span 1;
}

// Column Offsets
@mixin col-offset-1 {
	grid-column-start: 2;
}

@mixin col-offset-2 {
	grid-column-start: 3;
}

@mixin col-offset-3 {
	grid-column-start: 4;
}

@mixin col-offset-4 {
	grid-column-start: 5;
}

@mixin col-offset-5 {
	grid-column-start: 6;
}

@mixin col-offset-6 {
	grid-column-start: 7;
}

@mixin col-offset-7 {
	grid-column-start: 8;
}

@mixin col-offset-8 {
	grid-column-start: 9;
}

@mixin col-offset-9 {
	grid-column-start: 10;
}

@mixin col-offset-10 {
	grid-column-start: 11;
}

@mixin col-offset-11 {
	grid-column-start: 12;
}


// Column Starts
@mixin col-start-1 {
	grid-column-start: 1;
}

@mixin col-start-2 {
	grid-column-start: 2;
}

@mixin col-start-3 {
	grid-column-start: 3;
}

@mixin col-start-4 {
	grid-column-start: 4;
}

@mixin col-start-5 {
	grid-column-start: 5;
}

@mixin col-start-6 {
	grid-column-start: 6;
}

@mixin col-start-7 {
	grid-column-start: 7;
}

@mixin col-start-8 {
	grid-column-start: 8;
}

@mixin col-start-9 {
	grid-column-start: 9;
}

@mixin col-start-10 {
	grid-column-start: 10;
}

@mixin col-start-11 {
	grid-column-start: 11;
}

@mixin col-start-12 {
	grid-column-start: 12;
}

/* BOX SHADOWS */
@mixin subtle-lift {
	box-shadow: 0px 2px 20px rgba(29, 30, 35, 0.08);
}

@mixin blue-lift {
	box-shadow: 0px 4px 20px rgba(#003E6B, 0.5);
}

@mixin light-lift {
	box-shadow: 0px 8px 40px 8px rgba(0, 0, 0, 0.1);
}

@mixin medium-lift {
	box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
}

@mixin heavier-lift {
	box-shadow: 0px 20px 100px 40px rgba(0, 0, 0, 0.1);
}

/* GRADIENTS */
@mixin gradient-blue {
	background: linear-gradient(90deg, #1C5D9C 0%, #003E6B 100%);
}

@mixin gradient-orange {
	background: linear-gradient(315.61deg, #FF804D 24.73%, #FFC550 75.27%);
}

@mixin gradient-black {
	background: linear-gradient(180deg, rgba(22, 25, 28, 0) 0%, #16191C 100%);
}

@mixin gradient-white {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}


/* MASK */
@mixin mask($props) {
	-webkit-mask: $props;
	mask: $props;
}

@mixin mask-image($url) {
	-webkit-mask-image: $url;
	mask-image: $url;
}

@mixin mask-position($pos) {
	-webkit-mask-position: $pos;
	mask-position: $pos;
}

@mixin mask-size($size) {
	-webkit-mask-size: $size;
	mask-size: $size;
}

@mixin mask-repeat($repeat) {
	-webkit-mask-repeat: $repeat;
	mask-repeat: $repeat;
}


/* BTNS */
@mixin large-btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  padding: 12px 32px;
  border-radius: 36px;
}

@mixin medium-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.01em;
	padding: 8px 20px;
	border-radius: 28px;
}

@mixin orange-gadient-text {
	background: linear-gradient(270deg, #FFC550 0%, #FF804D 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}



