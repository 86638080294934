@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';


// grid-12
.container {
  margin: 48px auto;
  @include tablet {
    margin: 64px auto;
  }

  .inner {
    @include col-12;
    @include tablet {
      @include col-10;
      @include col-offset-1;
    }

    .head {
      text-align: center;
      margin-bottom: 16px;
      @include tablet {
        margin-bottom: 12px;
      }
    }

    .subhead {
      font-weight: 600;
      text-align: center;
      padding: 0 16px;
      margin-bottom: 24px;
      @include tablet {
        margin-bottom: 48px;
      }
    }

    .otherPackages {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 32px;
      @include desktop {
        flex-direction: row;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        width: 100%;
        padding: 12px 16px;
        color: $color-dark-blue;
        text-align: center;
        @include tablet {
          flex-direction: row;
          text-align: left;
        }

        p {
          span {
            font-weight: 700;
          }
        }
      }

      > div:first-child {
        background-color: $color-faded-neon-blue;
        :global(.btn) {
          color: $color-dark-blue;
          background-color: $color-bright-blue;
        }
      }

      > div:last-child {
        background-color: $color-faded-orange;
        :global(.btn) {
          color: $color-dark-blue;
          background-color: $color-orange;
        }
      }

    }
  }
}