@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';


// grid-12
.container {
  padding-top: 48px;
  padding-bottom: 48px;
  @include desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .inner {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include tablet {
      @include col-10;
      @include col-offset-1;
      gap: 48px;
    }

    .head {
      text-align: center;

      h2 {
        margin-bottom: 16px;
        @include desktop {
          margin-bottom: 8px;
        }
      }
    }
  }
}