@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

// grid-12
.container {
  padding-top: 48px;
  padding-bottom: 48px;
  @include desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .wifi {
    p {
      width: 50%;
    }
  }

  .inner {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include tablet {
      @include col-10;
      @include col-offset-1;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 32px;
    }

    .head {
      text-align: center;
      @include tablet {
        width: 100%;
        padding-bottom: 16px;
      }
    }

    :global(.main-text) {
      @extend %spacing-26;
      @include tablet {
        width: 10%;
        flex-grow: 1;
      }
    }

    .imgWrap {
      width: 100%;
      height: auto;
      @include desktop {
        width: 58.757%;
      }
  
      img {
        width: 100%;
        height: auto;
        display: block;
        border-radius: 12px;
        overflow: hidden;
        pointer-events:none;
      }
    }
  }

  
}