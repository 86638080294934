/* PALLETTE */
$color-blue-black: #001334;
$color-dark-blue: #003E6B;
$color-med-blue: #1A5E9D;
$color-bright-blue: #15BEF0;
$color-yellow: #FFC550;
$color-orange: #F79139;
$color-dark-orange: #F47637;

$color-faded-orange: #F7E2CF;
$color-faded-blue: #D8E2E7;
$color-faded-neon-blue: #D2EAF2;
$color-faded-yellow: #FDF9D3;

$color-black: #000;
$color-warm-black: #231F20;
$color-white: #FFF;
$color-success: #25C274;
$color-error: #F03D3E;

$color-ui-black: #1D1E23;
$color-ui-grey-1: #4B4B4B;
$color-ui-grey-2: #808080;
$color-ui-grey-3: #A4A4A4;
$color-ui-grey-4: #C7C7C7;
$color-ui-grey-5: #E1E2DE;
$color-ui-grey-6: #F7F7F7;

$color-ui-grey-blue-4: #BDC7CF;

$colors: (
  "blue-black": $color-blue-black,
  "dark-blue": $color-dark-blue,
  "med-blue": $color-med-blue,
  "bright-blue": $color-bright-blue,
  "yellow": $color-yellow,
  "orange": $color-orange,
  "dark-orange": $color-dark-orange,
  "faded-orange": $color-faded-orange,
  "faded-blue": $color-faded-blue,
  "faded-neon-blue": $color-faded-neon-blue,
  "faded-yellow": $color-faded-yellow,
  "black": $color-black,
  "warm-black": $color-warm-black,
  "white": $color-white,
  "success": $color-success,
  "error": $color-error,
  "ui-black": $color-ui-black,
  "ui-grey-1": $color-ui-grey-1,
  "ui-grey-2": $color-ui-grey-2,
  "ui-grey-3": $color-ui-grey-3,
  "ui-grey-4": $color-ui-grey-4,
  "ui-grey-5": $color-ui-grey-5,
  "ui-grey-6": $color-ui-grey-6,
  "ui-grey-blue-4": $color-ui-grey-blue-4
);

/* OTHER VARS */
$media-rules: (
		tablet-width-min: 720px,
		desktop-width-min: 960px,
		desktop-width-max: 1120px,
    medium-desktop-width-min: 1120px,
		medium-desktop-width-max: 1280px,
		large-desktop-width-min: 1280px,
		large-desktop-width-max: 1440px
);

$header-height-mobile: 52px;
$header-height-tablet: 84px;

/* FONTS */
$font-montserrat: 'Montserrat', sans-serif;
$font-gotham: 'Gotham Rounded', serif;

