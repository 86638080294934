form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include desktop {
    gap: 32px;
  }

  .select-container,
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &[data-desktop-half] {
      @include desktop {
        width: calc(50% + 16px);
      }
    }
  }

  .select-container {
    position: relative;
    &:after {
      content: '';
      @include mask(url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg"><path d="M12 16.2021L17 9.20215H7L12 16.2021Z"/></svg>') no-repeat center);
      width: 24px;
      height: 38px;
      background-color: $color-blue-black;
      display: block;
      position: absolute;
      bottom: 0;
      right: 12px;
    }
  }

  .multi-column-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    @include desktop {
      row-gap: 32px;
    }

    > * {
      width: 0;
      flex: 1 1 auto;
    }

    > *:nth-child(3) {
      width: 100%;
      @include desktop {
        width: 0;
      }
    }
  }

  label {
    font-weight: 500;
  }

  select,
  select:focus,
  input,
  input:focus {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 12px;
    border: 1px solid $color-ui-grey-4;
    border-radius: 8px;
  }

  select {
    -webkit-appearance: none;
    appearance: none;
  }

  ::-webkit-input-placeholder,
  ::placeholder {
    color: $color-ui-grey-3;
  }

  button[type=submit] {
    margin-top: 16px;
    @include desktop {
      margin-top: 0;
    }
  }
}