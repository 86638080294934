@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  background-color: $color-white;
  @include tablet {
    grid-template-columns: 50% 1fr;
    grid-template-rows: 100%;
    &[data-form-position=right] {
      .imgWrap {
        grid-area: 1/1;
      }
      .animWrap,
      .formWrap {
        @include col-offset-6;
        padding: 32px 0 32px 32px;
        @include desktop {
          padding: 32px;
        }
        @include medium-desktop {
          padding: 48px;
        }
        @include large-desktop {
          padding: 75px 0 75px 64px;
        }
      }
    }

    :global(.grid-12) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row: 1;
      height: min-content;
      align-self: center;
    }
  }

  // parent is grid-12
  .animWrap,
  .formWrap {
    @include col-12;
    padding: 24px 0 48px;
    @include tablet {
      @include col-6;
      padding: 32px 32px 32px 0;
    }
    @include desktop {
      padding: 32px;
    }
    @include medium-desktop {
      padding: 48px;
    }
    @include large-desktop {
      padding: 75px 64px 75px 0;
    }
  }

  .formWrap {
    h1, h2, h3, h4 {
      text-align: center;
      @include tablet {
        text-align: left;
        /* white-space: nowrap; */
      }
    }
  }

  .animWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    @include desktop {
      gap: 32px;
    }

    .wildCircle {
      width: 124px;
      height: 124px;
      background-image: url(../../images/wild-circle.svg);
      background-size: contain;
      background-position: center;
      will-change: transform;
			animation: rotate360 2.6s infinite linear;
			animation-fill-mode: backwards;
      @include desktop {
        width: 196px;
        height: 196px;
      }
    }

    h3 {
      width: fit-content;
    }
  }

  .imgWrap {
    z-index: 0;
    grid-area: img;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    grid-area: 1/1;
    z-index: 1;
    @include tablet {
      grid-area: 1/2;
      max-height: 786px;
    }

    &[data-design-el-position=top] {
      .designElement {
        top: 0;
        bottom: unset;
        transform: rotate(180deg);
      }
    }

    &[data-full-width=false] {
      padding: 0 16px;
      display: flex;
    }

    .imgWrapInner {
      display: grid;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 0;
      &[data-full-width=false] {
        width: auto;
        height: auto;
        max-width: 500px;
        margin: 80px auto;
        border-radius: 16px;
        overflow: hidden;
        z-index: 2;
        @include subtle-lift;
        align-self: center;
        @include tablet {
          margin: 80px auto;
        }
      }

      > * {
        grid-area: 1/1;
        position: relative;
      }

      .imgContentWrap {
        padding: 32px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        @include desktop {
          padding: 64px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      pointer-events:none;
      /* @include tablet {
        height: 100%;
      } */
    }

    .designElement {
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
      width: 37.659%;
      background-image: url(../../images/orange-radient-lines.svg);
      background-size: contain;
      aspect-ratio: 1/1;
      transform: rotate(90deg);
      &[data-design-el=blue-radient-lines] {
        background-image: url(../../images/blue-radient-lines.svg);
      }
    }
  }
}