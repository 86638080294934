@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include tablet {
    margin-top: 0px;
    gap: 0px;
  }
}