@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.container {
  margin: 48px auto;
  @include desktop {
    margin: 60px auto;
  }

  .content {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include tablet {
      :global(.btn) {
        max-width: 60%;
      }
    }
    @include desktop {
      gap: 48px;

      :global(.btn) {
        max-width: 39.7865%;
      }
    }

    h2 {
      text-align: center;
      margin: 0 auto;
      @include tablet {
        max-width: 92%;
      }
      @include desktop {
        max-width: 917px;
      }
    }

    .featureWrap {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include tablet {
        flex-direction: row;
      }
    }

    .feature {
      width: 100%;
      padding: 32px;
      border-radius: 8px;
      color: $color-dark-blue;

      &:nth-child(1) {
        background-color: $color-faded-neon-blue;
      }

      &:nth-child(2) {
        background-color: $color-faded-orange;
      }

      &:nth-child(3) {
        background-color: $color-faded-yellow;
        .iconWrap svg {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $color-yellow;
        }
      }

      .iconWrap {
        width: 32px;
        height: 32px;
      }

      h3 {
        margin: 16px 0;
      }

      /* ul {
        margin: 0;
      } */
    }
  }
}