@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.hero {
  width: 100%;
  background-color: $color-dark-blue;
  position: relative;
  @include tablet {
    max-height: 880px;
    padding-bottom: 48px;
    display: grid;
    > * {
      grid-area: 1/1;
    }
  }

  .imageWrap {
    width: 100%;
    height: auto;
    position: relative;

    .heroImage {
      position: relative;
      width: 100%;
      height: auto;
      object-fit: cover;
      z-index: 0;
      @include tablet {
        max-height: 880px;
      }
    }

    .wave {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      aspect-ratio: 1/0.19338;
      z-index: 2;
      @include mask(url(../../../images/wave-shape-m.svg) no-repeat 100%);
      @include mask-size(contain);
      background-color: $color-dark-blue;
      @include tablet {
        aspect-ratio: 1/0.11527;
        @include mask-image(url(../../../images/wave-shape-d.svg));
      }
    }

    .radientLines {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40.712%;
      height: auto;
      aspect-ratio: 1/1;
      z-index: 1;
      transform: translateY(-22%) rotate(90deg);
      background-image: url(../../../images/orange-radient-lines.svg);
      background-size: contain;
      @include tablet {
        max-width: 678px;
        max-height: 678px;
        width: 47.083%;
        transform: translateY(-1%) rotate(90deg);
      }
    }
  }

  // .grid-12
  .content {
    width: 100%;
    position: relative;
    z-index: 3;
    margin-top: -26%;
    @include tablet {
      align-self: end;
      margin-top: 0;
    }

    .innerContent {
      @include col-12;
      max-width: 420px;
      padding: 24px 24px 32px;
      margin: 0 auto;
      background-color: $color-ui-grey-6;
      border-radius: 16px;
      @include medium-lift;
      @include tablet {
        @include col-8;
        max-width: 533px;
        margin: 0;
        padding: 56px;
      }
      @include desktop {
        margin: 0 0 0 9%;
      }

      p {
        margin: 16px 0 24px;
        letter-spacing: 0.005em;
        @include desktop {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.01em;
          margin: 24px 0;
        }
      }
    }
  }
}