@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

// grid-12
.underHero {
  padding: 48px 16px;
  @include tablet {
    padding: 60px 32px;
  }
  @include large-desktop {
    padding: 60px 64px;
  }

  h2 {
    @include col-12;
    text-align: center;
    color: $color-white;
    margin-bottom: 24px;
  }

  .content {
    @include col-12;
    text-align: center;
    @extend %spacing-24;
    @include tablet {
      @include col-10;
      @include col-offset-1;
    }
    @include desktop {
      text-align: left;
      column-count: 2;
      column-gap: 32px;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      color: $color-white;
      @include tablet {
        @include col-10;
        @include col-offset-1;
      }
    }
  }

  .btnWrap {
    @include col-12;
    margin-top: 24px;
  }

  .imgWrap {
    @include col-12;
    margin-top: 48px;
    @include tablet {
      @include col-10;
      @include col-offset-1;
      border-radius: 12px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
      border-radius: 4px;
      overflow: hidden;
      pointer-events:none;
      @include tablet {
        border-radius: 8px;
      }
      @include desktop {
        border-radius: 12px;
      }
    }
  }

  
}