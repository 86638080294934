@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.hero {
  width: 100%;
  position: relative;
  /* overflow: hidden; */

  // grid-12
  .container {
    gap: 0;
    padding: 0;
    @include tablet {
      padding: 0 64px;
    }
    @include desktop {
      padding: 0 80px;
    }

    .innerContainer {
      @include col-12;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      grid-template-areas: "img" "h1";
      @include tablet {
        grid-template-columns: auto auto;
        grid-template-rows: 100%;
        grid-template-areas: "h1 img";
      }
      @include desktop {
        grid-template-columns: 32% 68%;
        font-size: 1.059vw;
      }
      @include x-large-desktop {
        font-size: 100%;
      }

      .headlineWrap {
        position: relative;
        z-index: 1;
        grid-area: h1;
        height: 100%;
        padding: 24px;
        background-color: $color-dark-blue;
        @include tablet {
          min-height: 522px;
          padding: 36px;
          display: inline-grid;
          > *:not(:first-child) {
            grid-area: 1/1;
            @include tablet {
              align-self: flex-end;
            }
          }
        }
        @include desktop {
          padding: em(48);
          min-height: em(627);
        }
        /* @include medium-desktop {
          padding: 40px;
          min-height: 580px;
        } */
        @include x-large-desktop {
          padding: 48px;
          min-height: 627px;
        }
  
        h1 {
          color: $color-white;
          width: fit-content;
          position: relative;
          z-index: 3;
          @include desktop {
            font-size: em(64);
            line-height: 1em;
          }
          @include x-large-desktop {
            font-size: 64px;
            line-height: 64px;
          }

          &[data-smaller] {
            @include desktop {
              font-size: em(54);
              line-height: 1.074em;
            }
            @include x-large-desktop {
              font-size: 54px;
              line-height: 58px;
            }
          }
        }

        h4 {
          color: $color-white;
          width: fit-content;
          position: relative;
          z-index: 3;
          @include desktop {
            font-size: em(28);
            line-height: 1.1428em;
          }
          @include x-large-desktop {
            font-size: 28px;
            line-height: 32px;
          }
        }

        .designElement {
          position: absolute;
          right: 0;
          top: 0;
          aspect-ratio: 1/1;
          background-size: contain;
          @include tablet {
            right: unset;
          }

          &[data-type=wild-circle] {
            height: 135%;
            background-image: url(../../images/wild-circle.svg);
            transform: translate(50%, -45%) rotate(-161deg);
            @include tablet {
              width: 117%;
              height: auto;
              transform: translateY(-41%) rotate(-137.66deg);
              justify-self: center;
              right: unset;
              max-width: 478px;
            }
            &[data-custom-color=true] {
              @include mask-image(url(../../images/wild-circle.svg));
              aspect-ratio: 0.9916/1;
            }
          }

          &[data-type=circle-of-dots] {
            height: 121.5%;
            background-image: url(../../images/circle-of-dots.svg);
            transform: translate(56%, -31%) rotate(90deg);
            @include tablet {
              width: 111.11%;
              height: auto;
              transform: translateY(-41%) rotate(-90deg);
              justify-self: center;
              right: unset;
              max-width: 478px;
            }
            &[data-custom-color=true] {
              @include mask-image(url(../../images/circle-of-dots.svg));
            }
          }

          &[data-type=orange-radient-lines],
          &[data-type=blue-radient-lines] {
            height: 176.38%;
            transform: translate(49%, -38%) rotate(3deg);
            @include tablet {
              height: 56.778%;
              transform: translate(-80px, -1px) scale(-1);
              justify-self: start;
              right: unset;
              left: 0;
              max-width: 478px;
            }
          }
          &[data-type=orange-radient-lines] {
            background-image: url(../../images/orange-radient-lines.svg);
            &[data-custom-color=true] {
              @include mask-image(url(../../images/orange-radient-lines.svg));
            }
          }
          &[data-type=blue-radient-lines] {
            background-image: url(../../images/blue-radient-lines.svg);
            &[data-custom-color=true] {
              @include mask-image(url(../../images/blue-radient-lines.svg));
            }
          }

          &[data-custom-color=true] {
            @include mask-size(contain);
            background-image: unset;
            aspect-ratio: 1/1;
          }
        }
      }
    
      .imageWrap {
        position: relative;
        z-index: 0;
        grid-area: img;
        width: 100%;
        height: auto;
        position: relative;
  
        img {
          width: 100%;
          height: auto;
          display: block;
          object-fit: cover;
          pointer-events:none;
          @include tablet {
            height: 96.17225%;
            border-radius: 0px 0px 12px 0px;
            overflow: hidden;
          }
        }
      }
    }

    
  }

}