@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';

.headeline {
  @include tablet {
    width: 90% !important;
  }
}

// grid-12
.caption {
  padding: 24px;
  @include tablet {
    padding: 40px 60px 60px;
  }
  @include desktop {
    padding: 40px 80px 60px;
  }

  p {
    @include col-12;
    color: $color-dark-blue;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.11px;
    text-align: center;
    @include desktop {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.14px;
    }
  }
}


.jobsSection {
  background-color: white;
  padding: 48px 0;
  padding: 60px 0 80px;

  /* parent grid-12 */
  h2 {
    @include col-12;
    color: $color-dark-blue;
    text-align: center;
    margin-bottom: 24px;
    @include desktop {
      margin-bottom: 48px;
    }
  }

  /* parent grid-12 */
  .container {
    @include col-12;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}