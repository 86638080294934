@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';


.container {
  @include col-12;
  padding: 40px 0 48px;
  @include desktop {
    @include col-10;
    @include col-offset-1;
    padding: 56px 0 60px;
  }

  .content {
    color: $color-dark-blue;
    margin-top: 24px;
    @include desktop {
      margin-top: 32px;
    }

    h1 {
      color: $color-dark-blue;
      font-weight: 700;
    }

    h2 {
      color: $color-dark-blue;
      font-weight: 700;
    }

    h3 {
      color: $color-dark-blue;
      font-weight: 600;
      margin-top: 12px;
    }

    p, ul, ol {
      margin: 24px 0;
      @include desktop {
        margin: 32px 0;
      }
    }

    h2 + p,
    h3 + p,
    p + ul,
    p + ol {
      margin-top: 4px;
    }

    p:has(+ ul),
    p:has(+ ol) {
      margin-bottom: 4px;
    }

    li {
      margin: 12px 0;
    }

    li > ul,
    li > ol,
    li p:first-child {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }

    ol[data-bold-num] {
      > li::marker {
        font-weight: bold;
      }
    }

    ol[data-no-indent],
    ul[data-no-indent] {
      padding: 0 0 0 20px;

      > li {
        margin: 24px 0;
        @include desktop {
          margin: 32px 0;
        }
      }
    }
  }
}