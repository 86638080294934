@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

// grid-12
.container {
  padding-top: 48px;
  padding-bottom: 48px;

  .inner {
    @include col-12;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include tablet {
      @include col-10;
      @include col-offset-1;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 32px;
    }

    .head {
      text-align: center;
      @include tablet {
        width: 100%;
      }

      h2 {
        text-align: center;
        margin-bottom: 16px;
        @include tablet {
          margin-bottom: 12px;
        }
      }
    }

    :global(.main-text) {
      @extend %spacing-26;
      @include tablet {
        width: 10%;
        flex-grow: 1;
      }
    }

    .imgWrap {
      width: 100%;
      height: auto;

      &.topImg {
        @include desktop {
          width: 58.757%;
        }
        img {
          border-radius: 12px;
          pointer-events:none;
        }
      }

      p {
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.005em;
        margin-top: 8px;
      }
    }

    .btm {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      p {
        margin: 16px 0;
      }
    }
  }
}