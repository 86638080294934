@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

header {
  width: 100%;
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header {
  @extend %grid-12;
  align-items: center;
  height: $header-height-mobile;
  padding: 0 16px;
  grid-template-areas: "left left left left left left right right right right right right";
  @include tablet {
    height: $header-height-tablet;
    padding: 0 32px;
  }
  @include desktop {
    padding: 0 40px;
    grid-template-areas: "left left nav nav nav nav nav nav nav right right right";
    position: unset;
    transition: height 0.25s;
    &[data-scroll="true"] {
      height: $header-height-mobile;
      .logo {
        width: 86px;
      }
    }
  }

  &__left-wrap {
    grid-area: left;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__right-wrap {
    grid-area: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    @include desktop {
      width: max-content;
      justify-self: end;
    }

    @include large-desktop {
      gap: 24px
    }

    .header__nav-link {
      display: none;
      @include desktop {
        display: initial;
      }
    }
  }

  .mobile-menu-btn {
    width: 24px;
    height: 24px;
    @include desktop {
      display: none;
    }
  }

  .logo {
    width: 86px;
    height: auto;
    @include tablet {
      width: 140px;
    }
    @include desktop {
      transition: width 0.25s;
    }

    img {
      width: 100%;
      height: auto;
      pointer-events:none;
    }
  }
}

