@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';


// grid-12
.underHero {
  margin: 24px auto;
  @include tablet {
    margin: 40px auto;
  }

  p {
    @include col-12;
    padding: 0 8px;
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.005em;
    color: $color-dark-blue;
    @include tablet {
      @include col-10;
      @include col-offset-1;
      padding: 0 16px;
    }
  }
}