@import '/src/scss/settings/vars';
@import '/src/scss/settings/mixins';
@import '/src/scss/settings/extensions';

.sticker {
  font-family: $font-gotham;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $color-dark-orange;
  background-color: $color-yellow;
  border-radius: 50%;
  width: 148px;
  height: 141px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  transform: rotate(6.91deg);
  right: -16px;
  bottom: 144px;
  @include tablet {
    right: 48px;
    bottom: -12px;
  }
  @include desktop {
    font-size: 31px;
    line-height: 31px;
    width: 211px;
    height: 202px;
    right: 58px;
    bottom: -16px;
  }
}

.getStarted {
  @extend %grid-12;
  
  .content {
    @include col-12;
    @extend %spacing-26;
    color: $color-white;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    padding: 48px 0;
    @include tablet {
      @include col-10;
      @include col-offset-1;
    }
    @include desktop {
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.005em;
      padding: 60px 0 76px;
    }

    :global(.btn) {
      margin: 32px auto 0;
      background: linear-gradient(315.61deg, #FF804D 24.73%, #FFC550 75.27%);
      @include tablet {
        max-width: 312px;
      }
      @include desktop {
        max-width: 412px;
        margin: 48px auto 0;
      }
    }
  }
}