h1,h2,h3,h4 {
  color: $color-orange;
}

h2,h3,h4 {
  font-family: $font-montserrat;
}

.headline {

  &__h1 {
    font-family: $font-gotham;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.02em;
    @include desktop {
      font-size: 64px;
      line-height: 64px;
    }
  }

  &__h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
    @include desktop {
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -0.015em;
    }

    &[data-medium-desktop] {
      @include desktop {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.01em;
      }
      @include medium-desktop {
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.015em;
      }
    }
  }

  &__h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.005em;
    &[data-subtitle] {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
    &[data-subtitle-alt] {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.01em;
    }
    &[data-full-size] {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.01em;
    }
    @include desktop {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.01em;
    }
  }

  &__h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    @include desktop {
      font-size: 20px;
      &[data-desktop-large] {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.005em;
      }
    }
  }
}